import React, { useEffect } from 'react';

import SEO from 'components/seo/index';

import Layout from 'layout/index';
import triggers, { loadDonateSuccessPage } from 'assets/data/awConversionId';
import { isArray } from "lodash";

// markup
const GiftingSuccess = () => {
  const gTag = awConversionId => {
    if (typeof window !== "undefined" && "gtag" in window && typeof window.gtag === 'function' && awConversionId) {
      if (typeof awConversionId === "string") {
        window.gtag("event", "conversion", {
          send_to: awConversionId,
          transaction_id: "",
        })
      } else if (isArray(awConversionId)) {
        awConversionId.forEach(a => {
          window.gtag("event", "conversion", {
            send_to: a,
            transaction_id: "",
          })
        })
      }
    }
  }

  const eventCallbackTriggers = (pageSlug, eventName) => {
    triggers
      .filter(x => isArray(x.pageSlug) ? x.pageSlug.includes(pageSlug) : x.pageSlug === pageSlug)
      .forEach(x => {
        let { gtags } = x;
        if (gtags) {
          gtags
            .filter(({ event }) => event && (isArray(event) ? event.includes(eventName) : event === eventName))
            .forEach(({ awConversionId }) => {
              gTag(awConversionId)
            })
        }
      })
  }

  useEffect(() =>
    eventCallbackTriggers('forest-fund', loadDonateSuccessPage), []
  )

  return (
    <>
      <SEO title='Forests By Heartfulness | donation success' />
      <Layout>
        <div className='container'>
          <div className='row '>
            <div className='col-md-12'>
              <section className='section'>
                <div className='section-title'>
                  <h3 className='text-start'>
                    <br /> Donation <span className='green'>Success</span>
                  </h3>
                </div>
                <h5>
                  Thank you for honoring the occasion by planting trees through &#34; Forests By Heartfulness &#34;.
                  Indeed a noble gesture!! You and the recipient will shortly receive the gift certificate by email.
                </h5>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default GiftingSuccess;
